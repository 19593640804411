import Cookieconsent from '@triggerfishnl/cookieconsent';
import Core from '@/core/module';

export default class Cookies extends Core {
    init() {
        this.Cookieconsent = new Cookieconsent({
            language: 'lang',
            styleType: 'fixed-bottom',
            cookieConsentTypes: ['marketing', 'statistics', 'essential'],
            messages: {
                lang: window.translations.cookies,
            },
        });
    }
}
