import { TweenMax } from 'gsap';
import Core from '@/core/module';
import GA from '@/helpers/ga';

export default class Search extends Core {
    init() {
        this.addEventListeners();
        this.opened = false;
    }

    addEventListeners() {
        $(window).on('keydown', (e) => {
            if (e.keyCode === 27) {
                this.close();
            }
        });

        this.$el.on('click', '[close]', (e) => {
            e.preventDefault();
            this.close();
        });

        this.$el.on('click', '[open]', (e) => {
            e.preventDefault();
            this.open();
        });

        this.$el.on('click', '[toggle]', (e) => {
            e.preventDefault();
            this.toggle();
        });
    }

    close() {
        TweenMax.to('.search-bar__bar', 0.2, {
            opacity: 0,
            onComplete: () => {
                $('.search-bar__bar').hide();
            },
        });

        this.opened = false;
    }

    open() {
        this.$el.find('.search-bar__bar').show();
        TweenMax.fromTo('.search-bar__bar', 0.2, { opacity: 0 }, { opacity: 1 });

        setTimeout(() => {
            this.$el.find('input').focus();
        }, 100);

        this.opened = true;

        GA.event({
            category: 'search',
            action: 'click',
            label: 'open from menu',
        });
    }

    toggle() {
        if (this.opened) {
            this.close();
        } else {
            this.open();
        }
    }
}
