import Core from '@/core/module';

export default class Multifield extends Core {
    init() {
        this.addEventListeners();
    }

    addEventListeners() {
        this.$el.on('click', '[add-field]', (e) => {
            e.preventDefault();

            const $field = this.$el.find('.formfield:first').clone();
            const $input = $field.find('input');
            $field.addClass('formfield--extra');
            $input.val('');

            $field.find('.formfield__inner').append(`
                <button class="formfield__delete" delete-item>
                    <i class="icon-trash"></i>
                </button>
            `);

            $field
                .find('[data-errorgroup]')
                .attr('data-errorgroup',
                    $field
                        .find('[data-errorgroup]')
                        .attr('data-errorgroup')
                        .replace('[0]', `[${this.$el.find('input').length}]`));

            $field.insertAfter(this.$el.find('.formfield:last'));

            setTimeout(() => {
                $field.find('input').focus();
            });
        });

        this.$el.on('click', '[delete-item]', (e) => {
            e.preventDefault();
            const $el = $(e.currentTarget).closest('.formfield');

            $el.remove();
        });
    }
}
