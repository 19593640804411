import { TweenMax } from 'gsap';
import Core from '@/core/module';

export default class ModuleBtnanimated extends Core {
    init() {
        TweenMax.staggerFromTo(this.$el.find('span'),
            1.5,
            { autoAlpha: 0.8, scale: 0 },
            { repeat: -1, autoAlpha: 0, scale: 1 },
            0.2);
    }
}
