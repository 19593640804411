import _ from 'lodash';
import Core from '@/core/module';
import Cookie from '@/helpers/cookie';

export default class Pagestack extends Core {
    init() {
        this.setDefaults({
            titleElement: 'meta[name="pagetitle"]',
            linkObject: '[history-back-btn]',
            cookieName: 'PageStack',
            resetContainer: false,
        });

        this.options.linkObject = $(this.options.linkObject);

        this.checkLastPage();
        this.addEventListeners();
    }

    /**
     * check if this page is prev in history
     * @return this
     */
    checkLastPage() {
        const historyLastPage = _.nth(this.historyObject, -2);
        const currentLastPage = _.nth(this.historyObject, -1);

        // only page refresh
        if (currentLastPage && currentLastPage.href === window.location.href) {
            // for now do nothing..
        } else if (historyLastPage && historyLastPage.href === window.location.href) {
            const object = this.historyObject;

            object.pop();

            this.historyObject = object;
        } else {
            this.pushCurrentPage();
        }

        this.updateButton();

        return this;
    }

    /**
     * Check what prev button status is..
     * @return self
     */
    updateButton() {
        const historyLastPage = _.nth(this.historyObject, -2);

        if (historyLastPage && historyLastPage.name && historyLastPage.href) {
            this.options.linkObject.show();
            this.options.linkObject.find('[page-name]').text(historyLastPage.name);
            this.options.linkObject.attr('href', historyLastPage.href);
        } else {
            this.options.linkObject.hide();
        }

        return this;
    }

    pushCurrentPage() {
        const object = this.historyObject;

        object.push({
            href: window.location.href,
            name: $(this.options.titleElement).attr('content'),
        });

        this.historyObject = object;

        return this;
    }

    get historyObject() {
        return Cookie.get(this.options.cookieName, JSON) || [];
    }

    set historyObject(data) {
        Cookie.set(this.options.cookieName, {
            value: data,
            expiration: 3600 * 24 * 365,
        });
    }

    addEventListeners() {
        if (this.options.resetContainer) {
            $(document).on('click', 'a', (e) => {
                const $el = $(e.currentTarget);

                if ($el.closest(this.options.resetContainer).length > 0) {
                    Cookie.delete(this.options.cookieName);
                }
            });
        }
    }
}
