import { TweenMax } from 'gsap';
import Core from '@/core/module';
import GA from '@/helpers/ga';

export default class Mobilemenu extends Core {
    init() {
        this.setDefaults({
            toggleElement: '.mobile-nav__toggle',
            container: '.mobile-nav',
            bg: '.mobile-nav-bg',
            overlay: '.mobile-nav-overlay',
            isOpen: false,
        });

        this.addEventListeners();
    }

    addEventListeners() {
        this.$el.on('click.mobileMenu', this.options.toggleElement, (e) => {
            e.preventDefault();

            this.toggle();
        });

        this.$el.on('click.mobileMenu', this.options.overlay, (e) => {
            e.preventDefault();

            this.close();
        });

        this.$el.on('click', '.mobile-nav__item--has-sub > a', (e) => {
            e.preventDefault();

            const $el = $(e.currentTarget).closest('.mobile-nav__item--has-sub');
            const subHTML = $el.find('.mobile-nav__item-sub').html();

            $('.mobile-nav__sub').html('').html(subHTML);

            TweenMax.to(this.$el.find('.mobile-nav__slide--sub'), 0.4, { x: '-100%' });
            TweenMax.to(this.$el.find('.mobile-nav__slide--main'), 0.3, { scale: 0.9, autoAlpha: 0 });
        });

        this.$el.on('click', '.mobile-nav__back', () => {
            TweenMax.to(this.$el.find('.mobile-nav__slide--sub'), 0.4, { x: '0%' });
            TweenMax.to(this.$el.find('.mobile-nav__slide--main'), 0.3, { scale: 1, autoAlpha: 1 });
        });
    }

    removeEventListeners() {
        $(this.options.toggleElement).off('click.mobileMenu');
        $(this.options.overlay).off('click.mobileMenu');
    }

    toggle() {
        if (this.options.isOpen) {
            this.close();

            this.options.isOpen = false;
        } else {
            this.open();

            this.options.isOpen = true;
        }
    }

    open() {
        if (this.options.isOpen) {
            return;
        }

        $('.mobile-nav__sub').html('');

        TweenMax.set(this.$el.find('.mobile-nav__slide--sub'), { x: '0%' });
        TweenMax.set(this.$el.find('.mobile-nav__slide--main'), { scale: 1, autoAlpha: 1 });

        this.options.isOpen = true;
        $(this.options.overlay).stop().show().animate({
            opacity: 0.2,
        }, 600);

        $(this.options.container).addClass('mobile-nav--show');
        $(this.options.bg).addClass('mobile-nav-bg--show');

        GA.event({
            category: 'mobile menu',
            action: 'click',
            label: 'open',
        });
    }

    close() {
        if (!this.options.isOpen) {
            return;
        }

        this.options.isOpen = false;
        $(this.options.overlay).stop().animate({
            opacity: 0,
        }, 600, () => {
            $(this.options.overlay).hide();
        });

        $(this.options.container).removeClass('mobile-nav--show');
        $(this.options.bg).removeClass('mobile-nav-bg--show');

        GA.event({
            category: 'mobile menu',
            action: 'click',
            label: 'close',
        });
    }
}
