import Masonry from 'masonry-layout';
import ImagesLoaded from 'imagesloaded';
import Core from '@/core/module';

export default class Articlelistener extends Core {
    init() {
        new ImagesLoaded(this.$el, () => {
            new Masonry(this.$el[0]);

            $(window).trigger('resize');
        });
    }
}
