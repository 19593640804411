import { TweenMax } from 'gsap';
import _each from 'lodash.foreach';
import ModuleAjaxform from '@/modules/ajaxform';

export default class AjaxForm extends ModuleAjaxform {
    addEventListeners() {
        super.addEventListeners();

        this.$el.on('click', '[nextstep]', (e) => {
            e.preventDefault();

            const $el = $(e.currentTarget);
            const $nextStep = $el.closest('.form__step').next();

            $el.closest('.formfield--button').remove();

            $nextStep.show();

            TweenMax.to('html, body', 0.55, {
                scrollTop: $nextStep.offset().top - 100,
            });

            TweenMax.fromTo($nextStep, 0.3,
                {
                    height: 0,
                },
                {
                    height: $nextStep[0].scrollHeight,
                    onComplete: () => {
                        $nextStep.css({
                            height: 'auto',
                        });
                    },
                });
        });

        this.$el.on('keyup', '.formfield--date input', () => {
            this.setDateFields();
        });
    }

    submit() {
        this.setDateFields();

        super.submit();
    }

    setDateFields() {
        _each($('.formfield--date'), (container) => {
            const $container = $(container);
            const $inputs = $container.find('input[date-item]');
            const values = [];

            _each($inputs, (input) => {
                const $input = $(input);

                if ($input.val()) {
                    values.push($input.val());
                }
            });

            $container.find('input[full-date]').val(values.join('-'));
        });
    }
}
