import { TweenMax } from 'gsap';
import Core from '@/core/module';

export default class Mainnav extends Core {
    init() {
        this.addEventListeners();
    }

    addEventListeners() {
        this.$el.on('click', 'div:not(.mainnav__nav) [subnav]:not(.sub-active) a', (e) => {
            e.preventDefault();

            const $el = $(e.currentTarget);
            const $item = $el.closest('li');
            const $subitems = $item.find('.mainnav__subitems').clone();

            if ($subitems.length > 0) {
                $('.sub-active').removeClass('sub-active');
                $item.addClass('sub-active').siblings().removeClass('sub-active');

                this.openNav($subitems, true);
            }
        });

        this.menuOpenTimeout = null;
        this.$el.on('mouseenter', '[subnav]', (e) => {
            window.clearTimeout(this.menuOpenTimeout);
            this.menuOpenTimeout = window.setTimeout(() => {
                const $el = $(e.currentTarget);
                this.menuIsClosing = false;
                window.clearTimeout(this.closeMenuTimeout);

                $('.sub-active').removeClass('sub-active');
                $el.addClass('sub-active');

                this.openNav($el.find('.mainnav__subitems').clone());
            }, 100);
        });

        this.closeMenuTimeout = null;
        this.menuIsClosing = false;

        $(document).on('mousemove', (e) => {
            if (
                $(e.target).closest('.mainnav__nav [subnav]').length === 0
                &&
                $(e.target).closest('.mainnav__sub').length === 0
                &&
                $(e.target).closest('.mainnav__topnav').length === 0
            ) {
                window.clearTimeout(this.menuOpenTimeout);

                if (this.menuIsClosing === true || !this.navOpen) {
                    return true;
                }

                this.menuIsClosing = true;
                window.clearTimeout(this.closeMenuTimeout);
                this.closeMenuTimeout = window.setTimeout(() => {
                    this.menuIsClosing = false;
                    this.closeNav();
                }, 500);
            } else {
                this.menuIsClosing = false;
                window.clearTimeout(this.closeMenuTimeout);
            }

            return true;
        });

        this.$el.on('click', '[subnav]:not(".sub-active") a', (e) => {
            e.preventDefault();
        });

        // this.$el.on('click', '[subnav].sub-active a', (e) => {
        //     // e.preventDefault();

        //     // if (this.navOpen) {
        //     //     return;
        //     // }

        //     // this.closeNav();
        // });

        this.$el.on('click', '.mainnav__bg', () => {
            this.closeNav();
        });

        $(window).on('scroll.mainnav', () => {
            if ($(window).scrollTop() > 100) {
                $('.mainnav').addClass('mainnav--sticky');
            } else {
                $('.mainnav').removeClass('mainnav--sticky');
            }
        }).trigger('scroll');

        this.$el.on('click', '[contact]', (e) => {
            const $el = $(e.currentTarget);
            const href = $el.attr('href');

            console.log(window.location.href, href);

            if (window.location.href === href) {
                e.preventDefault();

                const bounding = $('[form-name="contact"]')[0].getBoundingClientRect();

                TweenMax.to('body, html', 1, { scrollTop: (bounding.top + window.scrollY) - 150 });
            }
        });
    }

    hideCurrentNavItem() {
        return new Promise((resolve) => {
            TweenMax.to(this.$el.find('.mainnav__sub .mainnav__subitems'), 0.25, {
                y: -10,
                autoAlpha: 0,
                onComplete: resolve,
            });
        });
    }

    async openNav($items, disableHoverIndicator) {
        if (!disableHoverIndicator) {
            this.navOpen = true;
        }

        const oldHeight = this.$el.find('.mainnav__sub').height();

        if (this.$el.find('.mainnav__sub .mainnav__subitems').length > 0) {
            await this.hideCurrentNavItem();
        }

        this.$el.find('.mainnav__sub').empty().append($items);

        this.$el.find('.mainnav__sub').css({
            height: oldHeight,
        });

        TweenMax.fromTo('.mainnav__bg', 0.6, { display: 'block' }, { autoAlpha: 1 });

        const newHeight = this.$el.find('.mainnav__sub .mainnav__subitems').outerHeight();

        TweenMax.to(this.$el.find('.mainnav__sub'), 0.25, {
            height: newHeight,
            onComplete: () => {
                this.$el.find('.mainnav__sub').css({
                    height: 'auto',
                });
            },
        });

        TweenMax.fromTo(this.$el.find('.mainnav__sub .mainnav__subitems'), 0.25, {
            y: 10,
            autoAlpha: 0,
        }, {
            y: 0,
            autoAlpha: 1,
        });
    }

    closeNav() {
        this.navOpen = false;
        $('.sub-active').removeClass('sub-active');

        TweenMax.to(this.$el.find('.mainnav__sub .mainnav__subitems'), 0.25, {
            y: -10,
            autoAlpha: 0,
        });

        TweenMax.to('.mainnav__bg', 0.6, {
            autoAlpha: 0,
            onComplete: () => {
                $('.mainnav__bg').hide();
            },
        });

        TweenMax.to(this.$el.find('.mainnav__sub'), 0.25, {
            height: 0,
            onComplete: () => {
                this.$el.find('.mainnav__sub').empty();
            },
        });
    }
}
