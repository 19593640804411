import { TweenMax } from 'gsap';
import Core from '@/core/module';

export default class Scrolltocontent extends Core {
    init() {
        this.addEventListeners();
    }

    addEventListeners() {
        this.$el.on('click', (e) => {
            e.preventDefault();
            TweenMax.to('body, html', 1, { scrollTop: $('.header').outerHeight() });
        });
    }
}
