import _ from 'lodash';
import { TweenMax } from 'gsap';
import Core from '@/core/module';
import GA from '@/helpers/ga';
import ErrorTemplate from './error.html';

export default class Ajaxform extends Core {
    init() {
        this.setDefaults({
            close: '.close',
            defaultTimeout: 10,
            scrollToError: true,
            tracking: true,
            preventDefault: true,
            formname: (this.$el.attr('form-name')) || 'unnamed form',
            scrollContainer: 'body, html',
        });

        this.loadStatusCount = 0;

        if (this.$el.find('input[type=file]').length > 0) {
            (async () => {
                let Fileupload = await import('@/modules/fileupload');
                Fileupload = Fileupload.default;

                _.each(this.$el.find('.formfield--file'), (filefield) => {
                    const $filefield = $(filefield).find('input[type=file]');
                    const $originalField = $filefield.clone();

                    const data = {
                        name: $filefield.attr('name'),
                        url: $filefield.attr('url'),
                        placeholder: $filefield.attr('placeholder'),
                        multiple: $filefield.attr('multiple'),
                        maxFiles: Number($filefield.attr('max-files')),
                    };

                    const fileupload = new Fileupload($filefield, data);

                    fileupload.on('startUpload', () => {
                        this.addLoadStatus();
                    });

                    fileupload.on('endUpload', () => {
                        this.removeLoadStatus();
                    });

                    fileupload.on('remove', () => {
                        this.checkExtraUploadFieldButton(filefield, data.maxFiles);
                    });

                    $(filefield).on('click', '[add-file-field]', (e) => {
                        e.preventDefault();

                        const $el = $(e.currentTarget).closest('.multifield-add');

                        const $field = $originalField.clone();
                        $field.insertBefore($el);

                        const extraFileupload = new Fileupload($field, { ...data, extraField: true });

                        extraFileupload.on('startUpload', () => {
                            this.addLoadStatus();
                        });

                        extraFileupload.on('endUpload', () => {
                            this.removeLoadStatus();
                        });

                        extraFileupload.on('remove', () => {
                            this.checkExtraUploadFieldButton(filefield, data.maxFiles);
                        });

                        this.checkExtraUploadFieldButton(filefield, data.maxFiles);
                    });
                });
            })(this);
        }

        this.addEventListeners();
    }

    checkExtraUploadFieldButton(filefield, maxFiles) {
        const $filefield = $(filefield);

        const limitReached = $filefield.find('input[type="file"]').length < maxFiles;

        $filefield.find('[add-file-field]').toggle(limitReached);
    }

    addLoadStatus() {
        this.loadStatusCount += 1;

        this.loading = true;

        window.onbeforeunload = function onbeforeunload() {
            return true;
        };

        this.$el.addClass('form--loading');
    }

    removeLoadStatus() {
        this.loadStatusCount -= 1;

        window.onbeforeunload = null;

        if (this.loadStatusCount <= 0) {
            this.loading = false;
            this.$el.removeClass('form--loading');
        } else {
            this.loading = true;
            this.$el.addClass('form--loading');
        }
    }

    submit() {
        if (this.loading) {
            return;
        }

        this.addLoadStatus();

        this.$el.find('li[data-inputname]').remove();
        this.$el.find('.formfield--error').removeClass('formfield--error');
        this.$el.find('ul[data-errorgroup]').hide();

        this.request = $.ajax({
            url: this.$el.attr('action'),
            headers: {
                'Accept-Language': $('html').attr('lang'),
            },
            method: (this.$el.attr('method') && this.$el.attr('method') === 'post') ? 'post' : 'get',
            data: new FormData(this.$el[0]),
            dataType: 'JSON',
            processData: false,
            contentType: false,
            success: (data) => {
                this.removeLoadStatus();

                this.trigger('success', data, () => {
                    this.onSuccess(data);
                });

                GA.event({
                    category: `ajaxform ${this.options.formname}`,
                    action: 'send',
                    label: 'successfull',
                });
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.removeLoadStatus();

                // check if ajax is aborted
                if (jqXHR.status === 0) {
                    return;
                }

                if (jqXHR.status === 422) {
                    this.trigger('error', jqXHR.responseJSON, () => {
                        this.onError('ajax', { errors: jqXHR.responseJSON.errors });
                    });

                    GA.event({
                        category: `ajaxform ${this.options.formname}`,
                        action: 'send',
                        label: 'field error',
                    });

                    return;
                }

                this.trigger('fatalError', jqXHR, () => {
                    this.onError('xhr', { jqXHR, textStatus, errorThrown });
                });

                GA.event({
                    category: `ajaxform ${this.options.formname}`,
                    action: 'send',
                    label: `ajax error ${textStatus}`,
                });
            },
        });
    }

    onSuccess(data) {
        window.location = data.url;
    }

    onError(type, error) {
        if (type === 'xhr') {
            alert(`error: ${error.textStatus}`);
            console.error(error);
        } else if (type === 'ajax') {
            this.renderErrors(error.errors);
        }
    }

    renderErrors(errors) {
        _.each(errors, (error) => {
            const fieldError = error;
            if (error) {
                fieldError.inputName = error.inputName || error.name;

                const $ul = this.$el.find(`[data-errorgroup~="${error.inputName}"]`);

                this.$el.find(`li[data-inputname="${error.inputName}"]`).remove();

                $ul.append(ErrorTemplate(error));

                $ul.show();

                $ul.closest('.formfield').addClass('formfield--error');
            }
        });

        if (this.options.scrollToError) {
            const $errorEl = this.$el.find('[data-errorgroup]:visible:first');

            if ($errorEl.length > 0) {
                TweenMax.to($(this.options.scrollContainer), 0.75, {
                    scrollTop: $errorEl.offset().top - 150,
                });
            }
        }
    }

    toggleErrorGroups() {
        _.each(this.$el.find('[data-errorgroup]'), (error) => {
            const $error = $(error);

            $error.toggle(($error.find('li').length > 0));
        });
    }

    addEventListeners() {
        if (this.options.preventDefault) {
            this.$el
                .on('submit', (e) => {
                    e.preventDefault();

                    this.trigger('submit', {}, () => {
                        this.submit();
                    });
                });
        }

        this.$el
            .on('focus.ajaxForm change.ajaxForm', '[name]', (e) => {
                const $el = $(e.currentTarget);
                const name = $el.attr('name');

                this.hideError(name);
            });
    }

    hideError(name) {
        const $el = this.$el.find(`[data-inputname="${name}"]`);
        $el.closest('.formfield--error').removeClass('formfield--error');
        $el.remove();
        this.toggleErrorGroups();
    }

    removeEventListeners() {
        this.$el
            .off('focus.ajaxForm change.ajaxForm focus.ajaxForm');
    }
}
