import '../../sass/index.scss';
import _ from 'lodash';
import Router from '@/core/router';
import ModuleAjaxform from '@/modules/ajaxform/wrapper';
import ModulePagestack from '@/modules/pagestack';
import ModuleMobilenav from '@/modules/mobilenav';
import ModuleDoublecheck from '@/modules/doublecheck';
import ModuleMainnav from '@/modules/mainnav';
import ModuleCookie from '@/modules/cookie';
import ModuleBtnanimated from '@/modules/btnanimated';
import ModuleSearch from '@/modules/search';
import ModuleArticlelistener from '@/modules/articlelistener';
import ModuleScrollToContent from '@/modules/scrolltocontent';
import ModuleMultifield from '@/modules/multifield';
import CsrfToken from '@/modules/csrftoken';
import 'slick-carousel';

window._ = {
    escape: _.escape,
};

Router.setup({
    options: {
        pagecontainer: '.page-container',
        defaultTransition: 'fade',
        ajaxloading: false,
    },
    modules: [
        {
            class: ModuleMobilenav,
            element: '.mobile-nav',
            single: true,
        },
        {
            class: ModulePagestack,
            element: 'body',
            options: {
                excludeContainers: '.donttrack1, .donttrack2',
            },
        },
        {
            class: ModuleAjaxform,
            element: '[ajaxform]',
        },
        {
            load: () => import('@/modules/blog'),
            element: '[blog]',
        },
        {
            class: ModuleDoublecheck,
            element: 'body',
        },
        {
            class: ModuleMainnav,
            element: '.mainnav',
        },
        {
            class: ModuleBtnanimated,
            element: '.scrollbutton',
        },
        {
            class: ModuleCookie,
            element: document,
        },
        {
            class: ModuleSearch,
            element: '.search-bar',
        },
        {
            class: ModuleArticlelistener,
            element: '.partner-items',
        },
        {
            class: ModuleScrollToContent,
            element: '[scroll-to-content]',
        },
        {
            class: ModuleMultifield,
            element: '[multifield]',
            single: true,
        },
        {
            class: CsrfToken,
            element: '.csrf-token',
        },
    ],
});

setTimeout(() => {
    if ($('.contact-page').length > 0) {
        const bounding = $('[form-name="contact"]')[0].getBoundingClientRect();

        window.scrollTo(0, (bounding.top + window.scrollY) - 150);
    }
}, 100);

$('.logo-slider').slick({
    slidesToShow: 4,
    variableWidth: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,  
    arrows: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
    ],
});

$('.roadmap-slider').slick({
    slidesToShow: 4,
    variableWidth: false,
    arrows: true,
    infinite: false,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    responsive: [
        {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1.2,
          },
        },
    ],
});