import _ from 'lodash';
import Core from '@/core/module';

export default class Doublecheck extends Core {
    init() {
        _.each(this.$el.find('form'), (el) => {
            const $el = $(el);

            $el.find('[name="node[_doublecheck]"]').val(window.siteOptions.websiteID);
            $el.find('[name="_doubleCheck"]').val(window.siteOptions.websiteID);
        });
    }
}
